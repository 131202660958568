{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "id": 1,
        "name": "Gare de Torcy (Marne-la-Vallée)"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [
          [
            [
              [
                2.657655962951019,
                48.83491254338915
              ],
              [
                2.657055810445647,
                48.83451289594258
              ],
              [
                2.656297965760026,
                48.83425523493782
              ],
              [
                2.655456607219503,
                48.83416477991488
              ],
              [
                2.654614085033803,
                48.834250384417594
              ],
              [
                2.653852863273841,
                48.83450366966174
              ],
              [
                2.653247450720008,
                48.83489984441058
              ],
              [
                2.6528571092987,
                48.83540013091649
              ],
              [
                2.652720053140001,
                48.835955559765
              ],
              [
                2.65284970608226,
                48.8365117625348
              ],
              [
                2.653233384663914,
                48.83701429344256
              ],
              [
                2.653619453040299,
                48.837271393209996
              ],
              [
                2.653543676400171,
                48.837296604773016
              ],
              [
                2.652938229057131,
                48.83769277696754
              ],
              [
                2.652698350340948,
                48.838000201973855
              ],
              [
                2.652592442507791,
                48.83786148853388
              ],
              [
                2.651992294872954,
                48.83746181673575
              ],
              [
                2.651234432344404,
                48.837204124328856
              ],
              [
                2.651017368187447,
                48.8371807801618
              ],
              [
                2.650714964493365,
                48.83697938304081
              ],
              [
                2.649957115293926,
                48.836721682430046
              ],
              [
                2.649115727949003,
                48.83663118300439
              ],
              [
                2.648273155487577,
                48.83671674265369
              ],
              [
                2.647511866900757,
                48.83696998698392
              ],
              [
                2.646906377508881,
                48.83736612876303
              ],
              [
                2.64651595675846,
                48.83786639347071
              ],
              [
                2.646378826544234,
                48.83842181382654
              ],
              [
                2.646508417953944,
                48.838978022240866
              ],
              [
                2.646892053550407,
                48.839480572378285
              ],
              [
                2.647492185320069,
                48.839880269042276
              ],
              [
                2.648250068257826,
                48.8401379845413
              ],
              [
                2.648467143490679,
                48.840161333668185
              ],
              [
                2.648769556960424,
                48.840362735898296
              ],
              [
                2.649527453229447,
                48.84062044319313
              ],
              [
                2.650368905687291,
                48.84071093939939
              ],
              [
                2.651211539276516,
                48.84062536529343
              ],
              [
                2.651972863277131,
                48.8403720982457
              ],
              [
                2.65198361517988,
                48.84036506340952
              ],
              [
                2.652189420805032,
                48.84063460944257
              ],
              [
                2.652789606484605,
                48.84103427911658
              ],
              [
                2.653547532394376,
                48.84129196078674
              ],
              [
                2.654389002672124,
                48.841382428661134
              ],
              [
                2.655231640515604,
                48.841296826289636
              ],
              [
                2.65599295478844,
                48.84104353380982
              ],
              [
                2.656598417834891,
                48.84064734734228
              ],
              [
                2.65698876264171,
                48.84014705097615
              ],
              [
                2.657018713088195,
                48.840025644779196
              ],
              [
                2.657333076922626,
                48.83981993151178
              ],
              [
                2.657723408481342,
                48.83931963237783
              ],
              [
                2.657860420169529,
                48.83876419946885
              ],
              [
                2.657730708156622,
                48.83820800325192
              ],
              [
                2.657346977430205,
                48.83770548730689
              ],
              [
                2.656960887453381,
                48.83744839967017
              ],
              [
                2.657036662661279,
                48.83742318594585
              ],
              [
                2.657642075722208,
                48.837026992912236
              ],
              [
                2.658032383400792,
                48.836526691519886
              ],
              [
                2.658169384455522,
                48.83597125686928
              ],
              [
                2.658039676095894,
                48.83541505959802
              ],
              [
                2.657655962951019,
                48.83491254338915
              ]
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          2.385575,
          48.89724
        ]
      },
      "properties": {
        "id": 2,
        "name": "Cit\u00e9 des Sciences et de l\u2019Industrie (75019 Paris)"
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.859691,
              45.76887
            ],
            [
              4.859691,
              45.771897
            ],
            [
              4.866874,
              45.771897
            ],
            [
              4.866874,
              45.76887
            ],
            [
              4.859691,
              45.76887
            ]
          ]
        ]
      },
      "properties": {
        "id": 3,
        "name": "Charpennes - Charles-Hernu (69100 Villeurbanne)"
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          5.558801,
          46.671779
        ]
      },
      "properties": {
        "id": 4,
        "name": "Mairie - ECLA (Lons le Saunier)"
      }
    }
  ]
}
